import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const AcademicWritingServicePage = () => (
  <Layout
    pageWrapperClassName="academic-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Custom Academic Essay Writing Service to Help with Your Writing | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content: 'This page provides important information about our custom academic essay writing service and the conditions it ensures. Visit Takeawayessay.Com now and place an order to overcome your academic impediments and compose a perfect essay.',
        },

        {
          property: 'og:title',
          content: 'Custom Academic Essay Writing Service to Help with Your Writing | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'This page provides important information about our custom academic essay writing service and the conditions it ensures. Visit Takeawayessay.Com now and place an order to overcome your academic impediments and compose a perfect essay.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/academic-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Are academic papers
        <br className="first-screen-section__title__br" />
        {' '}
        too serious to spend
        <br className="first-screen-section__title__br" />
        {' '}
        your youth on?
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Get more free time for living your life
        <br className="first-screen-section__heading__br" />
        {' '}
        while our essay writing experts do the rest
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Academic essay writing service to assist with academic writing
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Students from all around the world know perfectly well what a writing process needs.
            You’re supposed to have advanced writing skills and reading comprehension, a good
            command of the subject, your private time (sometimes a lot), strength, and patience.
            Sometimes they lack one or several points out of the list and so, miserably fail. To
            avoid possible problems, they turn to our custom academic essay writing service because
            they know what we’re capable of. If you haven’t yet heard of us, we’ll provide the
            necessary information.
          </p>
          <p>
            We’re called TakeAwayEssay.com and we’re popular all around the globe.{' '}
            <strong>
              Thousands of students choose our academic essay writing service thanks to the variety
              of services we offer as well as our high quality. We always try to satisfy the demands
              of our clients and help them to resolve different types of academic assignments.
            </strong>{' '}
            If you select our company, you definitely win!
          </p>
          <h2 className="h3">What writers can help me with if I buy academic essays?</h2>
          <p>
            One of the first questions addressed to our company is related to quality. Our academic
            essay writing service for sale offers academic assignments of the top quality. We easily
            match the highest standards of any school, college or university. We have a professional
            staff of writers who know the official rules and their latest editions. They don’t
            violate the guidelines and so, complete papers precisely as you demand.
          </p>
          <p>
            TakeAwayEssay.com can write all types of essays. Besides, we easily cope with other
            pieces of writing. Thus, you’re free to order:
          </p>
          <ul className="dot-list">
            <li>Dissertation</li>
            <li>Coursework</li>
            <li>Research proposal</li>
            <li>Term paper</li>
            <li>Book/movie review</li>
            <li>Speech, etc.</li>
          </ul>
          <p>
            When you buy academic essay on our website, you likewise enjoy different academic
            services. Your assignment can be written and rewritten, edited and proofread, cited and
            referenced, etc. Our experts continuously enhance their competence to help you improve
            your own writing skills and receive better grades with less effort.
          </p>
          <p>
            A personal writer is selected automatically on the basis of your instructions. We select
            the most suitable helper to meet every requirement. After you pay, he or she begins to
            write. You may get in touch with your writer to discuss the terms of the writing process
            and check its status. Simply create a flexible schedule to contact your writers when
            it’s convenient for you.
          </p>
          <h2 className="h3">How much must I pay for you to help me write my academic essay?</h2>
          <p>
            Our legit custom company is rated high amongst the platforms of a similar type thanks to
            another crucial benefit, which is related to our pricing. If you wonder “how much money
            should be paid when I buy academic essay online on your website?” we can answer—“A fair
            price.” We never set unaffordable prices because it doesn’t make any sense. Our pricing
            is alternatively cheap and ordinary students will surely afford our assistance.
          </p>
          <p>
            When you place a request “write my academic essay,” provide us with the most important
            details. These are the desired quality of your assignment, its type, size, urgency,
            writing format, etc. Afterward, an online calculator provides an instant result. If it’s
            too much, alter any of your instructions to make the price cheaper. Thus, you always
            purchase assistance on your own terms.
          </p>
          <h2 className="h3">What guarantees do you provide when I pay for academic essay?</h2>
          <p>
            The quality of assistance, a variety of functions and affordable prices are extremely
            important. In the meanwhile, we know that our clients need more options. They commonly
            ask: “what other guarantees can you provide when you do my academic essay for me?” We
            have the answer to this important question. TakeAwayEssay.com ensures the following
            guarantees:
          </p>
          <ul className="dot-list">
            <li>
              <p>Uniqueness</p>
              <p>
                We release only 100% unique papers. We use a smart plagiarism checker that easily
                detects non-unique elements, which are instantly removed from the text. Our experts
                are familiar with different writing formats (APA, Chicago, Turabian, MLA, etc.) to
                quote your papers according to the rules.
              </p>
            </li>
            <li>
              <p>Confidentiality</p>
              <p>
                Our custom academic essay writing service does its best to protect its clients. Your
                confidential status is ensured with a trustworthy anti-virus program, the GDPR, and
                our privacy policy. It perfectly deflects different cyber threats and secures your
                personal information.
              </p>
            </li>
            <li>
              <p>Delivery</p>
              <p>
                We hire talented and experienced writers to beat the shortest deadlines. They are
                fast because they are experienced, self-confident, and skilled. They only require
                plain instructions to evaluate the odds. If the conditions are realistic, you’ll
                submit your assignment on time.
              </p>
            </li>
            <li>
              <p>Accessibility</p>
              <p>
                Sometimes the need comes when you least expect it and so, you may have an urgent
                order. Accordingly, we work 24/7. Thus, you’re welcome to visit our platform and
                place instant orders whenever you want. It’s likewise possible to ask questions
                using our chat. Competent technicians will provide the necessary responses.
              </p>
            </li>
          </ul>
          <p>
            These conditions are quite beneficial, aren’t they? If you buy custom academic essays on
            our website, they will be of the highest quality and accomplished on time. Place an
            order straight away and get professional assistance with your papers.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Hire our writers">
      Can you feel it?
      <br />
      {' '}
      It’s the air of freedom from boring assignments.
    </CTA>

  </Layout>
);

export default AcademicWritingServicePage;
